import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Form, message, Modal } from 'antd';

import { useExecution } from 'hooks/execution';
import { useAuth } from 'hooks/auth';
import { useFetch } from 'services/hooks';

import Button from 'components/Button';
import Card from 'components/Card';
import PageTitle from 'components/PageTitle';
import ZoomedImage from 'components/Image/ZoomedImage';
import { AlertTriangle } from '@combateafraude/icons/general';

const DeepfakeConfirmation = () => {
  const [form] = Form.useForm();
  const { tenantId, reportId, executionId } = useParams();
  const { executionData, reloadData } = useExecution();
  const { user } = useAuth();
  const { loading, post } = useFetch();

  const [sentImages, setSentImages] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [actionType, setActionType] = useState(null);

  useEffect(() => {
    if (!executionData) return;

    setSentImages(executionData.executions?.images);
  }, [executionData]);

  const handleConfirmAction = async () => {
    try {
      await post({
        url: `/executions-caf/users/${tenantId}/reports/${reportId}/executions/${executionId}/double-check/DEEPFAKE`,
        payload: {
          action: actionType === 'approved' ? 'APPROVED' : 'REPROVED',
          _id: user?._id,
          email: user?.username,
        },
      });

      message.success(
        `Selfie ${actionType === 'approved' ? 'aprovada' : 'reportada'} com sucesso!`
      );
      reloadData(true);
    } catch (err) {
      message.error('Falha ao realizar a ação.');
    } finally {
      setIsModalVisible(false);
    }
  };

  const showModal = (type) => {
    setActionType(type);
    setIsModalVisible(true);
  };

  return (
    <div>
      <div className="flex between-center gx-w-100">
        <PageTitle
          title="Ação requerida: verificação de Deepfake"
          subtitle="Avalie a imagem e confirme se a imagem é um Deepfake."
        />
      </div>
      <Form form={form} layout="horizontal" labelAlign="left" autoComplete="off">
        <Card>
          <Row align="middle" justify="space-around" className="mrg-btm-35">
            <Col span={8}>
              <ZoomedImage src={sentImages?.selfie} label="Selfie" />
            </Col>
            <Col justify="center" span={16}>
              <Row className="mrg-btm-10 mrg-top-20" justify="center">
                <span style={{ textAlign: 'center' }} className="mrg-btm-25">
                  Avalie se deve ser reprovada como um deepfake ou aprovada como uma
                  selfie autêntica, sem sinais de manipulação.
                </span>
              </Row>
              <Row className="mrg-btm-20" justify="center">
                <span className="text-bold">
                  Pontos de atenção para identificar possíveis manipulações:
                </span>
                <ul>
                  <li>
                    <span className="text-bold">Textura ou cor da pele:</span>{' '}
                    irregularidades, como diferença de tons entre o rosto e o pescoço, ou
                    áreas com aparência renderizada.
                  </li>
                  <li>
                    <span className="text-bold">Reflexo nos olhos:</span> ausência de
                    reflexo ou reflexos que não condizem com a iluminação da imagem.
                  </li>
                  <li>
                    <span className="text-bold">Detalhes de bordas:</span> borda do
                    cabelo, orelhas com aparência de recorte ou sinais de sobreposição de
                    uma imagem sobre outra.
                  </li>
                </ul>
              </Row>
              <Row justify="center">
                <Col>
                  <Button
                    type="primary"
                    onClick={() => showModal('approved')}
                    loading={loading}
                    className="mrg-btm-10 mrg-left-10"
                  >
                    Aprovar Selfie
                  </Button>
                </Col>
                <Col className="mrg-left-10">
                  <Button
                    type="danger"
                    onClick={() => showModal('reproved')}
                    loading={loading}
                    className="mrg-btm-10 mrg-right-10"
                  >
                    Reportar Deepfake
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Form>

      <Modal
        visible={isModalVisible}
        onOk={handleConfirmAction}
        onCancel={() => setIsModalVisible(false)}
        okText="Confirmar"
        cancelText="Cancelar"
        footer={false}
        header={false}
        centered
      >
        <Row justify="center" className="mrg-btm-20">
          <AlertTriangle width={50} height={50} color="#f89406" />
        </Row>
        <Row justify="center">
          <p>
            Você tem certeza que deseja{' '}
            <strong>{actionType === 'approved' ? 'aprovar' : 'reportar'}</strong> esta
            selfie?
          </p>
          <Row className="mrg-top-20">
            <Button
              type="secondary"
              onClick={() => setIsModalVisible(false)}
              disabled={loading}
              className="mrg-btm-10 mrg-right-10"
            >
              Cancelar
            </Button>
            <Button
              onClick={() => handleConfirmAction()}
              disabled={loading}
              loading={loading}
              className="mrg-btm-10 mrg-left-10 btn-custom btn-custom-primary"
            >
              Confirmar
            </Button>
          </Row>
        </Row>
      </Modal>
    </div>
  );
};

DeepfakeConfirmation.defaultProps = {
  review: false,
  isPrivate: false,
};

export default DeepfakeConfirmation;
