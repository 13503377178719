/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState, useReducer } from 'react';
import {
  Collapse,
  Radio,
  Select,
  DatePicker,
  Tooltip,
  Tag,
  Checkbox,
  Divider,
  Typography,
} from 'antd';

import {
  Calendar,
  ArrowRight1,
  CircleFalse,
  CheckCircle2,
  ArrowDown,
  ArrowRight,
  File,
  CheckSquare,
  Favorite,
  Export1,
  Trash,
  Import2,
} from '@combateafraude/icons/general';

import moment from 'moment';

import { useFetch } from 'services/hooks';

import Button from 'components/Button';

import { toMaskedCnpj } from 'utils/formatters';
import statusList from 'pages/private/Customers/CustomerList/utils/statusList.json';
import ExportFilterModal from './components/ExportFilterModal';
import ImportFilterModal from './components/ImportFilterModal';
import SaveFilterModal from './components/SaveFilterModal';
import DeleteFilterModal from './components/DeleteFilterModal';

import { convertForTimestamp } from './utils';
import './styles.less';

const { Panel } = Collapse;
const { Option } = Select;
const { RangePicker } = DatePicker;

const { Text } = Typography;

const defaultFilters = {
  creationDate: 'last24Hours',
  customCreatedAt: 'custom',
  startDate: undefined,
  endDate: undefined,
  status: [{ key: 'PROCESSANDO;PROCESSING', value: 'PROCESSANDO;PROCESSING' }],
  customers: undefined,
  notCustomers: undefined,
  search: undefined,
  documentoscopy: undefined,
  doubleCheckLiveness: undefined,
  reviewDeepfake: undefined,
  datasources: undefined,
  lifetime: 'threeMin',
  _order: undefined,
  _sort: undefined,
  appliedFilter: undefined,
};

const filtersReducer = (state, action) => {
  const appliedFilter = undefined;
  if (action.type === 'SET_CREATION_DATE') {
    return { ...state, creationDate: action.value, appliedFilter };
  }
  if (action.type === 'SET_CUSTOM_CREATED_AT') {
    return { ...state, customCreatedAt: action.value, appliedFilter };
  }
  if (action.type === 'SET_STATUS') {
    return { ...state, status: action.value, appliedFilter };
  }
  if (action.type === 'SET_CUSTOMERS') {
    return { ...state, customers: action.value, appliedFilter };
  }
  if (action.type === 'SET_NOT_CUSTOMERS') {
    return { ...state, notCustomers: action.value, appliedFilter };
  }
  if (action.type === 'SET_SEARCH') {
    return { ...state, search: action.value, appliedFilter };
  }
  if (action.type === 'SET_DOCUMENTOSCOPY') {
    return { ...state, documentoscopy: action.value, appliedFilter };
  }
  if (action.type === 'SET_DOUBLE_CHECK_LIVENESS') {
    return { ...state, doubleCheckLiveness: action.value, appliedFilter };
  }
  if (action.type === 'SET_REVIEW_DEEPFAKE') {
    return { ...state, reviewDeepfake: action.value, appliedFilter };
  }
  if (action.type === 'SET_DATASOURCES') {
    return { ...state, datasources: action.value, appliedFilter };
  }
  if (action.type === 'SET_LIFETIME') {
    return { ...state, lifetime: action.value, appliedFilter };
  }
  if (action.type === 'IMPORT_FILTERS') {
    const filtersToImport = action.value;

    const filters = {
      creationDate: filtersToImport.creationDate || defaultFilters.creationDate,
      customCreatedAt: filtersToImport.customCreatedAt || defaultFilters.customCreatedAt,
      startDate: filtersToImport.startDate || defaultFilters.startDate,
      endDate: filtersToImport.endDate || defaultFilters.endDate,
      status: filtersToImport.status || defaultFilters.status,
      customers: filtersToImport.customers || defaultFilters.customers,
      notCustomers: filtersToImport.notCustomers || defaultFilters.notCustomers,
      search: filtersToImport.search || defaultFilters.search,
      documentoscopy: filtersToImport.documentoscopy || defaultFilters.documentoscopy,
      doubleCheckLiveness:
        filtersToImport.doubleCheckLiveness || defaultFilters.doubleCheckLiveness,
      reviewDeepfake: filtersToImport.reviewDeepfake || defaultFilters.reviewDeepfake,
      datasources: filtersToImport.datasources || defaultFilters.datasources,
      lifetime: filtersToImport.lifetime || defaultFilters.lifetime,
      _order: filtersToImport._order || defaultFilters._order,
      _sort: filtersToImport._sort || defaultFilters._sort,
      appliedFilter,
    };

    return filters;
  }
  if (action.type === 'IMPORT_SAVED_FILTERS') {
    const filtersToImport = action.value;

    const filters = {
      creationDate: filtersToImport.creationDate || defaultFilters.creationDate,
      customCreatedAt: filtersToImport.customCreatedAt || defaultFilters.customCreatedAt,
      startDate: filtersToImport.startDate || defaultFilters.startDate,
      endDate: filtersToImport.endDate || defaultFilters.endDate,
      status: filtersToImport.status || defaultFilters.status,
      customers: filtersToImport.customers || defaultFilters.customers,
      notCustomers: filtersToImport.notCustomers || defaultFilters.notCustomers,
      search: filtersToImport.search || defaultFilters.search,
      documentoscopy: filtersToImport.documentoscopy || defaultFilters.documentoscopy,
      doubleCheckLiveness:
        filtersToImport.doubleCheckLiveness || defaultFilters.doubleCheckLiveness,
      reviewDeepfake: filtersToImport.reviewDeepfake || defaultFilters.reviewDeepfake,
      datasources: filtersToImport.datasources || defaultFilters.datasources,
      lifetime: filtersToImport.lifetime || defaultFilters.lifetime,
      _order: filtersToImport._order || defaultFilters._order,
      _sort: filtersToImport._sort || defaultFilters._sort,
      appliedFilter: filtersToImport.appliedFilter,
    };

    return filters;
  }
  if (action.type === 'RESET_FILTERS') {
    return defaultFilters;
  }
  return { state };
};

const Execution = ({ isLoadingList, onFiltersApply }) => {
  const {
    get: getFilters,
    data: filtersSaved,
    loading: loadingFilters,
    clearData: clearFilters,
  } = useFetch();
  const {
    get: getCustomers,
    data: customers,
    loading: loadingCustomers,
    clearData: clearCustomers,
  } = useFetch();
  const { data: notCustomers, clearData: clearNotCustomers } = useFetch();
  const {
    get: getDatasources,
    data: datasources,
    loading: loadingDatasources,
    clearData: clearDatasources,
  } = useFetch();

  const [searchCustomer, setSearchCustomer] = useState(undefined);
  const [searchNotCustomer, setSearchNotCustomer] = useState(undefined);
  const [allCustomers, setAllCustomers] = useState([]);
  const [customersSearched, setCustomersSearched] = useState([]);
  const [notCustomersSearched, setNotCustomersSearched] = useState([]);
  const [searchDatasource, setSearchDatasource] = useState(undefined);
  const [allDatasources, setAllDatasources] = useState([]);
  const [datasourcesSearched, setDatasourcesSearched] = useState([]);
  const [searchFilter, setSearchFilter] = useState(undefined);
  const [allSavedFilters, setAllSavedFilters] = useState([]);
  const [filtersSearched, setFiltersSearched] = useState([]);

  // filterOptions
  const [filtersState, dispatchFilters] = useReducer(filtersReducer, defaultFilters);

  // update executions controller
  const [shouldUpdate, setShouldUpdate] = useState(false);

  // modal controllers
  const [showImportModal, setShowImportModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [deleteModalData, setDeleteModalData] = useState({ show: false });

  const hideImportModal = useCallback(() => {
    setShowImportModal(false);
  }, []);

  const hideDeleteModal = useCallback(() => {
    setDeleteModalData({ show: false });
  }, []);

  const hideSaveModal = useCallback(() => {
    setShowSaveModal(false);
  }, []);

  const hideExportModal = useCallback(() => {
    setShowExportModal(false);
  }, []);

  const importFilters = useCallback(async () => {
    setShowImportModal(true);
  }, []);

  const exportFilters = () => {
    setShowExportModal(true);
  };

  const saveFilters = () => {
    setShowSaveModal(true);
  };

  useEffect(() => {
    if (shouldUpdate) {
      setShouldUpdate(false);
      onFiltersApply(filtersState);
    }
  }, [filtersState, onFiltersApply, shouldUpdate]);

  const cnpjFormattedToSearch = useCallback((cnpj) => {
    if (!cnpj) return undefined;

    const cnpjFormatted = cnpj
      ?.replaceAll('.', '')
      ?.replaceAll('/', '')
      ?.replaceAll('-', '');

    return cnpjFormatted;
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const data = await getFilters({
      url: '/query-filters',
    });

    const filters = data?.docs?.map((filter) => {
      return {
        name: filter?.name,
        id: filter._id,
        filterData: filter.filters,
      };
    });
    setAllSavedFilters(filters || []);
    setFiltersSearched(filters || []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteModalData, showSaveModal, showImportModal]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const data = await getDatasources({
      url: '/services',
      config: {
        params: {
          availableDatasources: 'true',
        },
      },
    });

    const titlesFiltered = data?.response?.filter((datasource) => datasource?.title);

    setAllDatasources(titlesFiltered || []);
    setDatasourcesSearched(titlesFiltered || []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const data = await getCustomers({
      url: '/clients',
      config: {
        params: {
          _offset: 0,
          _sort: 'fantasyName',
          _order: 1,
          _returnDocuments: false,
        },
      },
    });

    const customersFiltered = data?.docs?.filter(
      (customer) => customer?.fantasyName || customer?.companyName
    );

    setAllCustomers(customersFiltered || []);
    setCustomersSearched(customersFiltered || []);
    setNotCustomersSearched(customersFiltered || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFiltersSearch = useCallback(
    (searchTerm) => {
      if (searchTerm.trim().length === 0) {
        setFiltersSearched(allSavedFilters);
      }

      const filtersFiltered = allSavedFilters?.filter((filter) => {
        if (filter?.name?.toUpperCase().includes(searchTerm?.toUpperCase())) {
          return filter;
        }
        return false;
      });

      setSearchFilter(searchTerm);
      setFiltersSearched(filtersFiltered || []);
    },
    [allSavedFilters]
  );

  const onCustomerSearch = useCallback(
    (searchTerm) => {
      if (searchTerm.trim().length === 0) {
        setCustomersSearched(allCustomers);
      }

      const customersFiltered = allCustomers?.filter((customer) => {
        const cnpjFormatted = cnpjFormattedToSearch(customer?.cnpj);

        if (
          customer?.fantasyName?.toUpperCase().includes(searchTerm?.toUpperCase()) ||
          customer?.companyName?.toUpperCase().includes(searchTerm?.toUpperCase()) ||
          cnpjFormatted?.includes(searchTerm)
        ) {
          return customer;
        }

        return false;
      });

      setCustomersSearched(customersFiltered || []);
    },
    [allCustomers, cnpjFormattedToSearch]
  );

  const onNotCustomerSearch = useCallback(
    (searchTerm) => {
      if (searchTerm.trim().length === 0) {
        setNotCustomersSearched(allCustomers);
      }

      const notCustomersFiltered = allCustomers?.filter((customer) => {
        const cnpjFormatted = cnpjFormattedToSearch(customer?.cnpj);

        if (
          customer?.fantasyName?.toUpperCase().includes(searchTerm?.toUpperCase()) ||
          customer?.companyName?.toUpperCase().includes(searchTerm?.toUpperCase()) ||
          cnpjFormatted?.includes(searchTerm)
        ) {
          return customer;
        }

        return false;
      });

      setNotCustomersSearched(notCustomersFiltered || []);
    },
    [allCustomers, cnpjFormattedToSearch]
  );

  const onDatasourceSearch = useCallback(
    (searchTerm) => {
      if (searchTerm === '') {
        setDatasourcesSearched(allDatasources);
      }

      const datasourcesFiltered = allDatasources?.filter((datasource) => {
        if (
          datasource?.title?.toUpperCase().includes(searchTerm?.toUpperCase()) ||
          datasource?.sectionAttr?.toUpperCase().includes(searchTerm?.toUpperCase())
        ) {
          return datasource;
        }

        return false;
      });

      setDatasourcesSearched(datasourcesFiltered || []);
    },
    [allDatasources]
  );

  const updateFilters = useCallback(() => {
    setSearchCustomer(null);
    setSearchNotCustomer(null);
    setSearchDatasource(null);
    setSearchFilter(null);
    clearCustomers();
    clearNotCustomers();
    clearDatasources();
    clearFilters();

    setShouldUpdate(true);
  }, [clearCustomers, clearNotCustomers, clearDatasources, clearFilters]);

  const resetFilters = useCallback(() => {
    dispatchFilters({ type: 'RESET_FILTERS' });
    setShouldUpdate(true);
  }, []);

  const deleteFilterHandler = useCallback((event, filter) => {
    event.stopPropagation();
    setDeleteModalData({ show: true, filter });
  }, []);

  const applySavedFilterHandler = useCallback(
    (value) => {
      const filterToApply = allSavedFilters.filter((filter) => filter.id === value);
      dispatchFilters({
        type: 'IMPORT_SAVED_FILTERS',
        value: { ...filterToApply[0].filterData, appliedFilter: value },
      });
      setFiltersSearched(allSavedFilters);
    },
    [allSavedFilters]
  );

  const isCustomCreationDate = () => filtersState.creationDate?.substr(0, 6) === 'custom';

  const getDatePickerValue = () => {
    if (
      !isCustomCreationDate() ||
      filtersState.customCreatedAt === 'custom' ||
      !filtersState.customCreatedAt
    )
      return;
    let startDateFinal;
    let endDateFinal;

    if (Array.isArray(filtersState.customCreatedAt)) {
      const { startDate, endDate } = filtersState.customCreatedAt[0];

      startDateFinal = startDate;
      endDateFinal = endDate;
    } else {
      const [startDate, endDate] = filtersState.customCreatedAt.substr(7).split('-');
      startDateFinal = startDate;
      endDateFinal = endDate;
    }
    // eslint-disable-next-line consistent-return
    return [
      moment(new Date(parseInt(startDateFinal, 10))),
      moment(new Date(parseInt(endDateFinal, 10))),
    ];
  };

  return (
    <div id="custom-filter-component">
      <DeleteFilterModal
        isVisible={deleteModalData.show}
        onClose={hideDeleteModal}
        filter={deleteModalData.filter}
      />
      <SaveFilterModal
        isVisible={showSaveModal}
        onClose={hideSaveModal}
        filters={filtersState}
      />
      <ExportFilterModal
        isVisible={showExportModal}
        onClose={hideExportModal}
        filters={filtersState}
      />
      <ImportFilterModal
        isVisible={showImportModal}
        onClose={hideImportModal}
        onApplyFilters={dispatchFilters}
        onUpdateExecutions={updateFilters}
        onSave={saveFilters}
      />

      <Select
        mode="tags"
        value={filtersState.search}
        placeholder="Busque por nome, CPF ou CNPJ..."
        dropdownClassName="gx-d-none"
        className="execution-select"
        removeIcon={<CircleFalse />}
        onChange={(values) => {
          dispatchFilters({ type: 'SET_SEARCH', value: values });
        }}
        style={{ width: 291 }}
      />

      <Text className="saved-filters-title">Filtros salvos</Text>
      <Select
        value={filtersState.appliedFilter}
        className="saved-filters-select"
        placeholder="Busque pelo nome do filtro"
        optionLabelProp="label"
        dropdownClassName="multiselect-filters"
        filterOption={false}
        listHeight={320}
        dropdownAlign={{ offset: [0, 2] }}
        loading={loadingFilters}
        notFoundContent={
          !loadingFilters && !filtersSaved?.docs.length ? (
            <span>
              {searchFilter
                ? `Nenhum resultado encontrado para "${searchFilter}"`
                : 'Nenhum filtro salvo'}
            </span>
          ) : null
        }
        onSearch={onFiltersSearch}
        onClear={() => setFiltersSearched(allSavedFilters)}
        onBlur={() => setSearchFilter(null)}
        onChange={applySavedFilterHandler}
        style={{ width: 291 }}
      >
        {filtersSearched?.map((filter) => (
          <Option
            className="saved-filter-options"
            key={filter.id}
            label={filter.name}
            value={filter.id}
          >
            <div className="multiselect-option filter-option">
              <span className="flex center option-name">
                {filter.name.length > 25
                  ? filter.name.substr(0, 22).concat('...')
                  : filter.name}
              </span>
              <span className="flex center">
                <Button
                  className="filter-btn"
                  type="link"
                  onClick={(event) => deleteFilterHandler(event, filter)}
                >
                  <Trash width={30} height={30} className="gx-text-danger" />
                </Button>
              </span>
            </div>
          </Option>
        ))}
      </Select>

      <Collapse
        defaultActiveKey={['customer', 'creationDate', 'status']}
        ghost
        expandIconPosition="right"
        expandIcon={({ isActive }) =>
          isActive ? (
            <ArrowDown width={24} height={24} />
          ) : (
            <ArrowRight width={24} height={24} />
          )
        }
      >
        <Panel header="Por cliente" key="customer">
          <Select
            mode="multiple"
            labelInValue
            value={filtersState.customers}
            placeholder="Busque por nome ou e-mail..."
            optionLabelProp="label"
            dropdownClassName="multiselect-filters"
            filterOption={false}
            listHeight={320}
            dropdownAlign={{ offset: [0, 2] }}
            removeIcon={<CircleFalse />}
            menuItemSelectedIcon={<CheckCircle2 width={20} height={20} />}
            loading={loadingCustomers}
            notFoundContent={
              !loadingCustomers && !customers?.docs?.length ? (
                <span>
                  {searchCustomer
                    ? `Nenhum resultado encontrado para "${searchCustomer}"`
                    : 'Busque clientes por nome ou e-mail'}
                </span>
              ) : null
            }
            onSearch={onCustomerSearch}
            onClear={() => setCustomersSearched(allCustomers)}
            onBlur={() => setSearchCustomer(null)}
            onChange={(values) => {
              dispatchFilters({
                type: 'SET_CUSTOMERS',
                value: values,
              });
              setCustomersSearched(allCustomers);
            }}
            style={{ width: 291 }}
          >
            {customersSearched?.map((c) => (
              <Option
                key={c._id}
                label={c.fantasyName || c.companyName}
                value={c.tenantId}
              >
                <div className="multiselect-option">
                  <span className="option-title">{c.fantasyName || c.companyName}</span>
                  <div className="flex start-center">
                    <span className="option-subtitle">{toMaskedCnpj(c.cnpj)}</span>
                    <section className="mrg-left-10">
                      <>
                        {c?.verified && (
                          <Tooltip title="Em produção">
                            <CheckSquare
                              width={15}
                              height={15}
                              className="gx-text-success"
                            />
                          </Tooltip>
                        )}
                        {c?.hasContract && (
                          <Tooltip title="Possui contrato">
                            <File width={20} height={20} className="gx-text-primary" />
                          </Tooltip>
                        )}
                        {c?.status && (
                          <Tag
                            className="mrg-left-5 font-size-10"
                            color={statusList[c?.status || 'lead'].color}
                          >
                            {c?.status === 'internal-customer'
                              ? 'Interno'
                              : statusList[c?.status || 'lead'].name}
                          </Tag>
                        )}
                      </>
                    </section>
                  </div>
                </div>
              </Option>
            ))}
          </Select>
        </Panel>
        <Panel header="Excluir cliente" key="notCustomer">
          <Select
            mode="multiple"
            labelInValue
            value={filtersState.notCustomers}
            placeholder="Busque por nome ou e-mail..."
            optionLabelProp="label"
            dropdownClassName="multiselect-filters"
            filterOption={false}
            listHeight={320}
            dropdownAlign={{ offset: [0, 2] }}
            removeIcon={<CircleFalse />}
            menuItemSelectedIcon={<CheckCircle2 width={20} height={20} />}
            loading={loadingCustomers}
            notFoundContent={
              !loadingCustomers && !notCustomers?.docs?.length ? (
                <span>
                  {searchNotCustomer
                    ? `Nenhum resultado encontrado para "${searchNotCustomer}"`
                    : 'Busque clientes por nome ou e-mail'}
                </span>
              ) : null
            }
            onSearch={onNotCustomerSearch}
            onBlur={() => setSearchCustomer(null)}
            onClear={() => setNotCustomersSearched(allCustomers)}
            onChange={(values) => {
              dispatchFilters({ type: 'SET_NOT_CUSTOMERS', value: values });
              setNotCustomersSearched(allCustomers);
            }}
            style={{ width: 291 }}
          >
            {notCustomersSearched?.map((c) => (
              <Option
                key={c._id}
                label={c.fantasyName || c.companyName}
                value={c.tenantId}
              >
                <div className="multiselect-option">
                  <span className="option-title">{c.fantasyName || c.companyName}</span>
                  <div className="flex start-center">
                    <span className="option-subtitle">{toMaskedCnpj(c.cnpj)}</span>
                    <section className="mrg-left-10">
                      <>
                        {c?.verified && (
                          <Tooltip title="Em produção">
                            <CheckSquare
                              width={15}
                              height={15}
                              className="gx-text-success"
                            />
                          </Tooltip>
                        )}
                        {c?.hasContract && (
                          <Tooltip title="Possui contrato">
                            <File width={20} height={20} className="gx-text-primary" />
                          </Tooltip>
                        )}
                        {c?.status && (
                          <Tag
                            className="mrg-left-5 font-size-10"
                            color={statusList[c?.status || 'lead'].color}
                          >
                            {c?.status === 'internal-customer'
                              ? 'Interno'
                              : statusList[c?.status || 'lead'].name}
                          </Tag>
                        )}
                      </>
                    </section>
                  </div>
                </div>
              </Option>
            ))}
          </Select>
        </Panel>

        <Panel header="Excluir fonte indisponível" key="datasources">
          <Select
            mode="multiple"
            labelInValue
            value={filtersState.datasources}
            placeholder="Busque por nome da fonte..."
            optionLabelProp="label"
            dropdownClassName="multiselect-filters"
            filterOption={false}
            listHeight={320}
            dropdownAlign={{ offset: [0, 2] }}
            removeIcon={<CircleFalse />}
            menuItemSelectedIcon={<CheckCircle2 width={20} height={20} />}
            loading={loadingDatasources}
            notFoundContent={
              !loadingDatasources && !datasources?.title?.length ? (
                <span>
                  {searchDatasource
                    ? `Nenhum resultado encontrado para "${searchDatasource}"`
                    : 'Busque por nome da fonte'}
                </span>
              ) : null
            }
            onSearch={onDatasourceSearch}
            onBlur={() => setSearchDatasource(null)}
            onClear={() => setDatasourcesSearched(allDatasources)}
            onChange={(values) => {
              dispatchFilters({ type: 'SET_DATASOURCES', value: values });
              setDatasourcesSearched(allDatasources);
            }}
            style={{ width: 291 }}
          >
            {datasourcesSearched?.map((c) => (
              <Option key={c.sectionAttr} label={c.title} value={c.sectionAttr}>
                <div className="multiselect-option">
                  <span className="option-title">{c.title}</span>
                  <div className="flex start-center">
                    <span className="option-subtitle">{c.sectionAttr}</span>
                  </div>
                </div>
              </Option>
            ))}
          </Select>
        </Panel>

        <Panel header="Criado em" key="creationDate">
          {!isCustomCreationDate() && (
            <>
              <Checkbox.Group
                defaultValue={[]}
                value={!isCustomCreationDate() ? filtersState.lifetime : []}
                onChange={(e) => dispatchFilters({ type: 'SET_LIFETIME', value: e })}
              >
                <Checkbox value="threeMin">Criado &gt; 3 minutos</Checkbox>
              </Checkbox.Group>
              <Divider />
            </>
          )}
          <Radio.Group
            defaultValue=""
            value={isCustomCreationDate() ? 'custom' : filtersState.creationDate || ''}
            onChange={(e) =>
              dispatchFilters({
                type: 'SET_CREATION_DATE',
                value: e.target.value,
              })
            }
          >
            <Radio value="last24Hours">Últimas 24 horas</Radio>
            <Radio value="last7Days">Últimos 7 dias</Radio>
            <Radio value="last14Days">Últimos 14 dias</Radio>
            <Radio value="last30Days">Últimos 30 dias</Radio>
            <Radio value="custom">Intervalo personalizado</Radio>
          </Radio.Group>
          {isCustomCreationDate() && (
            <RangePicker
              className="gx-w-100 mrg-top-5 gx-bg-transparent"
              id="openOneCalendar"
              format="DD/MM/YYYY HH:mm"
              showTime
              suffixIcon={<Calendar width={20.5} height={20.5} />}
              separator={<ArrowRight1 />}
              value={getDatePickerValue()}
              onChange={(value) => {
                if (value) {
                  const { startDate, endDate } = convertForTimestamp(value);

                  dispatchFilters({
                    type: 'SET_CUSTOM_CREATED_AT',
                    value: [{ startDate, endDate }],
                  });
                } else {
                  dispatchFilters({
                    type: 'SET_CUSTOM_CREATED_AT',
                    value: 'custom',
                  });
                  dispatchFilters({
                    type: 'SET_CREATION_DATE',
                    value: 'custom',
                  });
                }
              }}
            />
          )}
        </Panel>
        <Panel header="Status" key="status">
          <Select
            mode="multiple"
            labelInValue
            value={filtersState.status}
            placeholder="Selecione um status..."
            optionLabelProp="label"
            dropdownClassName="multiselect-filters"
            filterOption={false}
            listHeight={320}
            dropdownAlign={{ offset: [0, 2] }}
            removeIcon={<CircleFalse />}
            menuItemSelectedIcon={<CheckCircle2 width={20} height={20} />}
            onChange={(values) => {
              dispatchFilters({
                type: 'SET_STATUS',
                value: values,
              });
            }}
            style={{ width: 291 }}
          >
            <>
              <Option key="PROCESSANDO;PROCESSING" label="Em Processamento">
                Em processamento
              </Option>
              <Option key="PENDENTE;PENDING" label="Pendentes de análise">
                Pendentes de análise
              </Option>
              <Option key="PENDENTE OCR;PENDING OCR" label="Pendentes de correção">
                Pendentes de correção
              </Option>
            </>
          </Select>
        </Panel>
        <Panel header="Documentoscopia" key="documentoscopy">
          <Select
            mode="multiple"
            labelInValue
            value={filtersState.documentoscopy}
            placeholder="Selecione..."
            optionLabelProp="label"
            dropdownClassName="multiselect-filters"
            filterOption={false}
            listHeight={320}
            dropdownAlign={{ offset: [0, 2] }}
            removeIcon={<CircleFalse />}
            menuItemSelectedIcon={<CheckCircle2 width={20} height={20} />}
            onChange={(values) => {
              dispatchFilters({
                type: 'SET_DOCUMENTOSCOPY',
                value: values,
              });
            }}
            style={{ width: 291 }}
          >
            <>
              <Option key="WAITING" label="Aguardando">
                Aguardando
              </Option>
              <Option key="APPROVED" label="Aprovada">
                Aprovada
              </Option>
              <Option key="REPROVED" label="Reprovada">
                Reprovada
              </Option>
              <Option key="UNSOLICITED" label="Não solicitada">
                Não solicitada
              </Option>
            </>
          </Select>
        </Panel>
        <Panel header="Double-check de Liveness" key="doubleCheckLiveness">
          <Select
            mode="multiple"
            labelInValue
            value={filtersState.doubleCheckLiveness}
            placeholder="Selecione..."
            optionLabelProp="label"
            dropdownClassName="multiselect-filters"
            filterOption={false}
            listHeight={320}
            dropdownAlign={{ offset: [0, 2] }}
            removeIcon={<i className="caf-ic_circle_false" />}
            menuItemSelectedIcon={<CheckCircle2 width={20} height={20} />}
            onChange={(values) => {
              dispatchFilters({
                type: 'SET_DOUBLE_CHECK_LIVENESS',
                value: values,
              });
            }}
            style={{ width: 291 }}
          >
            <>
              <Option key="WAITING" label="Aguardando">
                Aguardando
              </Option>
              <Option key="APPROVED" label="Aprovada">
                Aprovada
              </Option>
              <Option key="REPROVED" label="Reprovada">
                Reprovada
              </Option>
              <Option key="UNSOLICITED" label="Não solicitada">
                Não solicitada
              </Option>
            </>
          </Select>
        </Panel>
        <Panel header="Avaliação de Deepfake" key="reviewDeepfake">
          <Select
            mode="multiple"
            labelInValue
            value={filtersState.reviewDeepfake}
            placeholder="Selecione..."
            optionLabelProp="label"
            dropdownClassName="multiselect-filters"
            filterOption={false}
            listHeight={320}
            dropdownAlign={{ offset: [0, 2] }}
            removeIcon={<i className="caf-ic_circle_false" />}
            menuItemSelectedIcon={<CheckCircle2 width={20} height={20} />}
            onChange={(values) => {
              dispatchFilters({
                type: 'SET_REVIEW_DEEPFAKE',
                value: values,
              });
            }}
            style={{ width: 291 }}
          >
            <>
              <Option key="WAITING" label="Aguardando">
                Aguardando
              </Option>
              <Option key="APPROVED" label="Aprovada">
                Aprovada
              </Option>
              <Option key="REPROVED" label="Reprovada">
                Reprovada
              </Option>
              <Option key="UNSOLICITED" label="Não solicitada">
                Não solicitada
              </Option>
            </>
          </Select>
        </Panel>
      </Collapse>
      <div className="btn-filters mrg-right-15">
        <Button
          className="btn-custom btn-custom-primary"
          disabled={isLoadingList}
          block
          onClick={updateFilters}
        >
          Consultar
        </Button>
        <Button
          className="btn-custom-secondary"
          disabled={isLoadingList}
          block
          onClick={saveFilters}
        >
          <Favorite className="button-icon" /> Salvar Filtros
        </Button>
        <Button
          className="btn-custom-secondary"
          disabled={isLoadingList}
          block
          onClick={exportFilters}
        >
          <Export1 className="button-icon" /> Exportar filtros
        </Button>
        <Button
          className="btn-custom-secondary"
          disabled={isLoadingList}
          block
          onClick={importFilters}
        >
          <Import2 className="button-icon" />
          Importar filtros
        </Button>
        <Button
          type="link"
          className="link-button"
          disabled={isLoadingList}
          block
          onClick={resetFilters}
        >
          Limpar todos os filtros
        </Button>
      </div>
    </div>
  );
};

export default Execution;
